<template>
  <section v-if="license">
    <router-view :license="license"></router-view>
  </section>
</template>
<script>
export default {
  data() {
    return {
      license: null
    };
  },
  methods: {
    getLicense: function() {
      let self = this;
      this.$http
        .get(this.user.hostname + "/license")
        .then((response) => {
          self.license = response.data;
        })
        .catch(() => {
        });
    }
  },
  computed: {
    showOrganisations(){
      if(this.license.type == 'RS' || this.license.type == 'PA')
      {
        return true;
      }else{
        return this.license.multitenant == 1;
      }
    },
    showNodes(){
      return (this.license.type == 'RS' || this.license.type == 'PA');
    },
    showPartnerBilling() {
      return (this.license.type == 'PA');
    },
    showBilling(){
      if(this.license.type == 'RS' || this.license.type == 'PA')
      {
        return true;
      }else{
        return this.license.multitenant == 1;
      }
    }
  },
  mounted() {
    if(this.user.information.is_admin == 2)
    {
      this.getLicense();
    }
  }
};
</script>
<style></style>