var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.license
    ? _c("section", [_c("router-view", { attrs: { license: _vm.license } })], 1)
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }